import React from 'react';

import { AppStateContext } from '../../contexts/app-state-provider.context';
import { navigate } from 'gatsby';

export default class FrenchPatientInfoPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setLanguage('french');
    navigate('/patient-info');
  }

  render() {
    return <></>;
  }
}
